.nav_bar{

    background-color: rgb(0, 214, 170);
    position: fixed !important;
    top: 0 !important;
    width: 100vw;
    overflow: hidden;
    z-index: 1;
    button {
        border: none;
        outline: none;
        width: 100px;
        padding: 5px 0;
        color: #fff;
        font-size: 16px;
        letter-spacing: 1px;
        background: #00512a;
        cursor: pointer;
        margin-top: 10px ;
      }
}
.nav_item
{
    text-decoration: none;
    color: rgb(13, 13, 13);
    font-size: 1.2rem;
    margin-right: 30px;
}
.active
{
    color: rgb(255, 61, 61)!important;
    border-top: 5px solid rgb(34, 66, 0);
    transition:  0.3s;
    
}

.logo_txt
{
    font-size: 1.9rem;
    font-weight: 800;
}
.logo_img
{
    width:40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 2px;
}

@keyframes slideIn {
    from{
        left: -100;
    }
    to{
        left: 0;
    }
}

@media screen and (min-width: 990px) {
    .nav_bar button {
        margin-top: 0;
    }
    
    .home_btn
    {
        margin-left: 40vw;
    }

}