@import "./NavBar.scss";
@import "./Home.scss";
@import "Books.scss";
@import "./About.scss";
@import "./LogIn.scss";
@import './Card.scss';
@import "~bootstrap/scss/bootstrap";

body
{
    
    // background-image: linear-gradient(to left , rgba(255,0,0,0), rgb(0, 214, 170,1));
    background-color: rgb(0, 255, 204);
}

@media screen and (min-width: 990px) {
body {

        // background-image: linear-gradient(to left , rgba(255,0,0,0), rgb(0, 214, 170,1));
        background: linear-gradient(to left, rgba(255, 0, 0, 0), rgb(0, 214, 170, 1));
        height: auto;
        background-repeat: no-repeat;
        background-size: contain;
        background-attachment: fixed;
        scroll-behavior: smooth;

    }
}
