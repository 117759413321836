// .about_container
// {
//     padding-top: 100px;
//     .about_heading
//     {
//         display: flex;
//         justify-content: center;
//         font-size: 1.8rem;
//         font-weight: 900;
//         color: rgb(23, 86, 65);
//         margin-bottom: 2vh;
//         margin-top: 10vh;
//     }
//     .about_des
//     {
//         display: flex;
//         justify-content: center;
//         width: 80vw;
//         font-size: 1.4rem;
//         font-weight: 400;
//         margin: 2vh 6vw;
//         margin-left: 10vw;

//     }
  
// }

// @media screen and (min-width:990px) {
//     .about_container
// {
//     padding-top: 100px;
//     .about_heading
//     {
//         display: flex;
//         justify-content: center;
//         font-size: 2.4rem;
//         font-weight: 900;
//         color: rgb(23, 86, 65);
//         margin-bottom: 4vh;
//         margin-top: 10vh;
//     }
//     .about_des
//     {
//         display: flex;
//         justify-content: center;
//         width: 50vw;
//         font-size: 1.9rem;
//         font-weight: 400;
//         margin: 2vh 6vw;
//         margin-left: 25vw;

//     }
//     .about_footer
//     {
        
//         padding-left:10vw ;
//     }
// }
// }

*{
    margin: 0;
    padding: 0;
    font-family: "montserrat",sans-serif;
  }
  .testimonials{
    display: flex;
    padding: 40px 0;
    // background: #f1f1f1;
    color: #434343;
    text-align: center;
    padding-top: 80px;
    flex-direction: column;
  }
  .inner{
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
    padding: 0 20px;
  }
  
  .border{
    width: 160px;
    height: 5px;
    background: #6ab04c;
    margin: 26px auto;
  }
  
  .row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .col{
    flex: 33.33%;
    max-width: 33.33%;
    box-sizing: border-box;
    padding: 15px;
  }
  .testimonial{
    background: #fff;
    padding: 30px;
  }
  .testimonial img{
    width: 100px;
    height: 100px;
    border: 1px rgb(81, 76, 76) solid;
    border-radius: 50%;
  }
  .name{
    font-size: 20px;
    text-transform: uppercase;
    margin: 20px 0;
  }
  .stars{
    color: #6ab04c;
    margin-bottom: 20px;
  }
  
  .about_footer
  {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: rgba(79, 79, 79,0.5);
      color: black;
      font-weight: 500;
      position: fixed;
      width: 100vw;
      bottom: 0;
      padding-left:10vw ;
      .email_link{
        text-decoration: none;
        color: rgb(0, 0, 0);
        font-weight: 500;
      }
  }
  @media screen and (max-width:960px) {
  .col{
    flex: 100%;
    max-width: 80%;
  }
  }
  
  @media screen and (max-width:600px) {
  .col{
    flex: 100%;
    max-width: 100%;
  }
  }
  