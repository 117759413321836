
// .card_img
// {
//     width: 200px;
// }


.card
{
    max-width: 250px;
    margin: 5vh 24vw;
    margin-bottom: 5vh;
    height: fit-content;
    border-radius: 10px;
    border: 2px solid black;
    
}
.card_bottom
{
    background-color: rgb(146, 255, 188);
    padding: 10px;
}
.author
{
    display: flex;
    background-color: rgb(2, 255, 99);
    align-items: center;
    font-weight: 600;
    justify-content: center;
}
.more
{
    background-color: rgb(0, 131, 13);
    color: white;
    border-radius: 5px;
    border: 2px solid rgb(0, 91, 9);
}
@media screen and (min-width: 990px) {
    .card
    {
        margin: 40px;
        max-height: 350px;
        overflow: scroll;
        overflow-x: hidden;
        // max-width: 200px !important;
    }
    .card_cantainer
    {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        // box-sizing: border-box;
    }
}